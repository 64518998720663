import React, { Fragment } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Section from "../components/Section";
import { PreviewCard } from "../components/Blog";
import SEO from "../components/SEO";

const BlogPage = ({
    data,
    location
}) => {
    const { allGhostPost: { edges: posts = [] } = {} } = data;
    const headerChildren = <Fragment>
        <h1>Think Script</h1>
        <div className="header-meta text-secondary mt-2">
            <div>Thoughts, lessons & questions</div>
            <div>All things <span className="heavy">dev</span>, all the time</div>
        </div>
    </Fragment>;

    return (
        <Layout
            headerChildren={headerChildren}
            path={location.pathname}
        >
            <SEO
                title="Blog"
                pathname="/blog"
                description="All things web development: Tutorials, questions & thoughts"
            />
            <Section
                fluid
                margin="mx-8 mb-16 mt-8 md:my-16"
            >
                {posts.length > 0
                    ? posts.map((post, index) => <PreviewCard key={index} post={post.node} />)
                    : <h2>I haven't posted anything yet</h2>
                }
            </Section>
        </Layout>
    );
};

export default BlogPage;

export const indexQuery = graphql`
    query {
        allGhostPost(filter: {published_at: {gte: "2020-01-01"}}) {
            edges {
                node {
                    id
                    feature_image
                    slug
                    title
                    reading_time
                    excerpt
                    published_at(formatString: "MMMM DD, YYYY")
                }
            }
        }
    }
`;