import React from "react";
import { useSpring, animated } from "react-spring";
import { Link } from "gatsby";

import "./Blog.scss";

const PreviewCard = ({ post }) => {
    const [ imgSpring, set ] = useSpring(() => ({ transform: "scale(1)" }));
    const {
        feature_image,
        reading_time,
        excerpt,
        title,
        slug,
        published_at
    } = post;

    return (
        <div
            className="blog preview flex flex-col md:flex-row mx-auto"
            data-aos="fade-up"
        >
            <Link
                to={slug}
                className="img-link w-full md:w-6/12"
                aria-label={title}
            >
                <animated.img
                    onMouseEnter={() => set({ transform: "scale(1.1)" })}
                    onMouseLeave={() => set({ transform: "scale(1)" })}
                    style={imgSpring}
                    src={feature_image}
                    alt={`cover image for the post titled ${title}`}
                />
            </Link>
            <div className="flex flex-1 flex-col px-0 py-4 md:py-0 md:px-5 h-full overflow-hidden">
                <Link
                    to={`blog/${slug}`}
                    aria-label={title}
                >
                    <h3 className="heading small text-left mb-1">{title}</h3>
                </Link>
                <div className="text-muted mb-2">
                    <span>{published_at} &middot; {reading_time} min read</span>
                </div>
                <div className="flex-1">{excerpt}</div>
            </div>
        </div>
    );
};

export default PreviewCard;